
import { defineComponent } from "vue";
import Filter from "@/components/molecules/Filter.vue";
import MainCard from "@/components/molecules/MainCard.vue";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";

export default defineComponent({
  name: "AllPicsSection",
  data() {
    return {
      isModalOpen: false,
      data: {},
      popupImgData: null,
      load: true,
      dataBodyCheckbox: [
        { Type: ["Human", "Zombie", "Alien", "Bionic"] },
        { Gender: ["Male", "Femaile", "Ambiguous"] },
        {
          Rarity: [
            "Bronze",
            "Silver",
            "Gold",
            "Platinum",
            "Diamond",
            "Legendary",
          ],
        },
      ],
    };
  },
  components: {
    Filter,
    MainCard,
    DefaultPopup,
  },
  mounted() {
    this.getAllPics();
  },
  methods: {
    getAllPics() {
      this.$store.dispatch("getNFTs/getAllNFT").then(
        (response) => {
          this.data = response.data;
          this.load = false;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    openPic(img) {
      this.isModalOpen = true;
      this.popupImgData = img;
    },
    handleCloseModal() {
      this.isModalOpen = false;
    },
  },
});
